<template>
  <div
    id="root"
    :style="{
      //background: `url(${require('~@/assets/img/bg.jpg')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }"
  >
    <transition name="fade" mode="out-in">
      <router-view v-if="show" />
    </transition>
  </div>
</template>

<script>
  const appRoot = {
    name: "Root",
    components: {},
    data() {
      return {
        show: true,
      };
    },
    mounted() {
      this.$logSend.init("DreamWallLogger", this.$router);
    },
  };
  export default appRoot;
</script>

<style lang="scss">
// * { cursor: none; }
html,
body {
  height: 100%;
  overscroll-behavior: none;
}
body {
  min-height: 100%;
  background: url('~~@/assets/img/bg_new.jpg') repeat left top;
  background-size: 30%;
}

#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>