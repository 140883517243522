export const SET_CANVAS = 'SET_CANVAS'
export const SET_CANVAS_ISDRAWINGMODEACTIVE = 'SET_CANVAS_ISDRAWINGMODEACTIVE'
export const SET_LIGHTMARKER = 'SET_LIGHTMARKER'
export const SET_STRONGMARKER = 'SET_STRONGMARKER'
export const SET_BRUSH_COLOR = 'SET_BRUSH_COLOR'
export const SET_BRUSH = 'SET_BRUSH'
export const RESET_HISTORY = 'RESET_HISTORY'

// History
export const SHIFT_HISTORY_STACK = 'SHIFT_HISTORY_STACK'
export const SET_CANVAS_CURRENT_STATE = 'SET_CANVAS_CURRENT_STATE'
export const RESET_REDO_STACK = 'RESET_REDO_STACK'
export const SET_HISTORY_LOCK = 'SET_HISTORY_LOCK'
export const PUSH_STATE_TO_REDO_STACK = 'PUSH_STATE_TO_REDO_STACK'
export const PUSH_STATE_TO_HISTORY_STACK = 'PUSH_STATE_TO_HISTORY_STACK'
export const POP_STATE_FROM_HISTORY_STACK = 'POP_STATE_FROM_HISTORY_STACK'
export const POP_STATE_FROM_REDO_STACK = 'POP_STATE_FROM_REDO_STACK'

const getInitHistory = () => {
  return {
    currentState: '',
    stack: [],
    redoStack: [],
    maxStackLength: 10,
    isLocked: false,
  }
}

export default {
  [SET_CANVAS]: (state, canvas) => {
    state.canvas = canvas
  },
  [SET_CANVAS_ISDRAWINGMODEACTIVE]: (state, isDrawingModeActive) => {
    state.canvas.isDrawingMode = isDrawingModeActive

    // If drawing mode is activated, discard active selections
    if (isDrawingModeActive) {
      state.canvas.discardActiveObject().renderAll()
    }
  },
  [SET_LIGHTMARKER]: (state) => {
    state.canvas.freeDrawingBrush.width = 5
  },
  [SET_STRONGMARKER]: (state) => {
    state.canvas.freeDrawingBrush.width = 30
  },
  [SET_BRUSH_COLOR]: (state, colorCode) => {
    state.canvas.freeDrawingBrush.color = colorCode
  },
  [SET_BRUSH]: (state, brushObj) => {
    state.canvas.freeDrawingBrush = brushObj.type
    state.canvas.freeDrawingBrush.width = brushObj.width
  },
  // HISTORY
  [SHIFT_HISTORY_STACK]: (state) => {
    state.history.stack.shift()
  },
  [PUSH_STATE_TO_HISTORY_STACK]: (state, canvasState) => {
    state.history.stack.push(canvasState)
  },
  [PUSH_STATE_TO_REDO_STACK]: (state, canvasState) => {
    state.history.redoStack.push(canvasState)
  },
  [SET_CANVAS_CURRENT_STATE]: (state, currentCanvasState) => {
    state.history.currentState = currentCanvasState
  },
  [RESET_REDO_STACK]: (state) => {
    state.history.redoStack.length = 0
  },
  [SET_HISTORY_LOCK]: (state, isHistoryLocked) => {
    state.history.isLocked = isHistoryLocked
  },
  [POP_STATE_FROM_HISTORY_STACK]: (state) => {
    state.history.stack.pop()
  },
  [POP_STATE_FROM_REDO_STACK]: (state) => {
    state.history.redoStack.pop()
  },
  [RESET_HISTORY]: (state) => {
    state.history = getInitHistory()
  },
}