export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL'
export const SET_ACTIVE_SWATCH = 'SET_ACTIVE_SWATCH'
export const SET_SWATCHES = 'SET_SWATCHES'
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE'
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP'

export default {
  [SET_ACTIVE_TOOL]: (state, toolId) => {
    state.tools = state.tools.map(tool => ({ ...tool, isActive: tool.id === toolId ? true : false }))
  },
  [SET_ACTIVE_SWATCH]: (state, swatchId) => {
    state.swatches = state.swatches.map(swatch => ({ ...swatch, isActive: swatch.id === swatchId ? true : false }))
  },
  [SET_SWATCHES]: (state, swatches) => {
    state.swatches = swatches
  },
  [SET_ACTIVE_MODAL]: (state, modalId) => {
    state.modals = state.modals.map(modal => ({ ...modal, isActive: modal.id === modalId ? true : false }))
  },
  [SET_ACTIVE_GROUP]: (state, groupId) => {
    state.groups = state.groups.map(group => ({ ...group, isActive: group.id === groupId ? true : false }))
  },
  [SET_ACTIVE_ARTICLE]: (state, articleId) => {
    state.articles = state.articles.map(article => ({ ...article, isActive: article.id === articleId ? true : false }))
  },
}