import Vue from "vue";
import * as signalR from "@microsoft/signalr";
export default {
  install(__instance) {

    if (
      typeof fetch !== "undefined" &&
      typeof AbortController === "undefined"
    ) {
      console.warn(
        "Fetch is supported, but not AbortController.  Dropping default fetch so SignalR can override."
      );
      window.fetch = undefined;
    }

    const connection = new signalR.HubConnectionBuilder()
      // .withUrl("https://gooseserverdev.eu.ngrok.io/goose")
      .withUrl(process.env.VUE_APP_LOGSEND_URL || "https://ggdb.io/goose")
      .withAutomaticReconnect()
      .build();
    const startPromise = new Promise(resolve => {
      connection.start().then(() => {
        console.log("signalR connected");

        resolve();
      });
    });

    const signalr = new Vue({});

    signalr._send = async (level, what) => {
      await startPromise;
      await connection.send("SendLog2", level, what, signalr._appId);
    };

    signalr._argsToJsonString = args => {
      return JSON.stringify({
        page: signalr._router.currentRoute.path,
        message: [...args]
      });
    };

    signalr.init = async (appId, router) => {
      signalr._appId = appId;
      signalr._router = router;
      signalr._ori_log = window.console.log;
      signalr._ori_error = window.console.error;
      signalr._ori_warn = window.console.warn;
      signalr._ori_trace = window.console.trace;

      window.console.log = function() {
        signalr._ori_log.apply(console, arguments);
        signalr._send("log", signalr._argsToJsonString(arguments));
      };

      window.console.error = function() {
        signalr._send("error", signalr._argsToJsonString(arguments));
        signalr._ori_error.apply(console, arguments);
      };

      window.console.warn = function() {
        signalr._send("warn", signalr._argsToJsonString(arguments));
        signalr._ori_warn.apply(console, arguments);
      };

      window.console.trace = function() {
        signalr._send("trace", signalr._argsToJsonString(arguments));
        signalr._ori_trace.apply(console, arguments);
      };
    };

    signalr.listen = async callback => {
      await startPromise;
      await connection.send("SubscribeToGroup", "logs_" + signalr._appId);
      connection.on("LogEvent", (level, message, clientInfo) => {
        const received = JSON.parse(message);
        signalr._ori_log("RECEIVED ", message);
        callback(level, received.page, received.message, clientInfo);
      });
    };

    __instance.prototype.$logSend = signalr;
  }
};
