export default {
  tools: (state) => state.tools,
  activeTool: (state) => state.tools.find(tool => tool.isActive),
  swatches: (state) => state.swatches,
  activeSwatch: (state) => state.swatches.find(swatch => swatch.isActive),
  modals: (state) => state.modals,
  activeModal: (state) => state.modals.find(modal => modal.isActive),
  articles: (state) => state.articles,
  activeArticle: (state) => state.articles.find(article => article.isActive),
  activeGroup: (state) => state.groups.find(group => group.isActive)
}