export default {
  tools: [
    {
      id: "lightMarker",
      type: "brush",
      isActive: false,
      group: "default",
    },
    {
      id: "strongMarker",
      type: "brush",
      isActive: false,
      group: "default",
    },
    {
      id: "highlighter",
      type: "brush",
      isActive: false,
      group: "default",
    },
    {
      id: "bucket",
      type: "bucket",
      isActive: true,
      group: "",
    },
    {
      id: "smudge",
      type: "brush",
      isActive: false,
      group: "default"
    },
    {
      id: "eraser",
      type: "brush",
      isActive: false,
      group: "default"
    },
    {
      id: "stickers",
      type: "element",
      isActive: false,
      group: "group2"
    },
    {
      id: "quotes",
      type: "handwriting",
      isActive: false,
      group: "group2"
    },
    {
      id: "borchie",
      type: "element",
      isActive: false,
      group: "group3"
    },
    {
      id: "brillanti",
      type: "element",
      isActive: false,
      group: "group3"
    },
    {
      id: "charms",
      type: "element",
      isActive: false,
      group: "group3"
    },
    {
      id: "customContent",
      isActive: false,
    },
  ],
  swatches: [],
  modals: [
    {
      id: "colorWheel",
      isActive: false,
    },
    {
      id: "handWriting",
      isActive: false,
    },
    {
      id: "reset",
      isActive: false,
    },
    {
      id: "download",
      isActive: false,
    },
  ],
  groups: [
    {
      id: "default",
      isActive: true,
    },
    {
      id: "group2",
      isActive: false,
    },
    {
      id: "group3",
      isActive: false,
    },
  ],
  articles: [
    {
      id: 'shoe4',
      isActive: false,
    },
    {
      id: 'shoe2',
      isActive: false,
    },
    {
      id: 'shoe1',
      isActive: false,
    },
    {
      id: 'shoe3',
      isActive: false,
    },
    {
      id: 'bag1',
      isActive: false,
    },
    {
      id: 'bag3',
      isActive: false,
    },
    {
      id: 'bag2',
      isActive: false,
    },
    {
      id: 'tshirt1',
      isActive: false,
    },
    {
      id: 'jacket1',
      isActive: false,
    },
    {
      id: 'jacket2',
      isActive: false,
    },
    {
      id: 'sweater1',
      isActive: false,
    },
    {
      id: 'jeans1',
      isActive: false,
    }
  ]
}