const getDefaultState = () => {
  return {
    canvas: {},
    history: {
      currentState: '',
      stack: [],
      redoStack: [],
      maxStackLength: 10,
      isLocked: false,
    }
  }
}

const state = getDefaultState();

export default state;