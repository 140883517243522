import {
  SET_CANVAS,
  SET_CANVAS_ISDRAWINGMODEACTIVE,
  SET_BRUSH,
  SET_BRUSH_COLOR,
  SHIFT_HISTORY_STACK,
  SET_CANVAS_CURRENT_STATE,
  SET_HISTORY_LOCK,
  PUSH_STATE_TO_REDO_STACK,
  PUSH_STATE_TO_HISTORY_STACK,
  POP_STATE_FROM_HISTORY_STACK,
  RESET_REDO_STACK,
  POP_STATE_FROM_REDO_STACK,
  RESET_HISTORY,
} from './mutations'

export default {
  setCanvas: ({ commit }, canvas) => {
    commit(SET_CANVAS, canvas)
  },
  setCanvasDrawingMode: ({ commit }, isDrawingModeActive) => {
    commit(SET_CANVAS_ISDRAWINGMODEACTIVE, isDrawingModeActive)
  },
  setBrush: ({ commit, dispatch, getters }, brushObj) => {
    brushObj.width = brushObj.width || getters.canvas.freeDrawingBrush.width
    commit(SET_BRUSH, brushObj);
    if (brushObj.color) {
      dispatch('setBrushColor', brushObj.color)
    }
  },
  setBrushColor: ({ commit }, colorCode) => {
    commit(SET_BRUSH_COLOR, colorCode)
  },
  saveCanvasState: ({ commit, getters }) => {
    if (!getters.isHistoryLocked) {
      // Drop oldest element
      if (getters.historyStack.length > getters.maxStackLength) {
          commit(SHIFT_HISTORY_STACK)
      }

      //Make the state of the canvas the current state
      commit(SET_CANVAS_CURRENT_STATE, getters.canvas.toDatalessJSON([
        'lockMovementX',
        'lockMovementY',
        'hasControls',
        'selectable',
        'evented',
        'hoverCursor',
        'targetFindTolerance',
        'isDrawingMode',
        'selection',
        'preserveObjectStacking',
        'id',
        'width',
        'height',
        'originX',
        'originY',
        'scaleX',
        'scaleY',
        'mb',
        'ml',
        'mr',
        'mt',
        'left',
        'top',
        'aCoords',
      ]))

      //Add the current state
      commit(PUSH_STATE_TO_HISTORY_STACK, getters.currentState)

      //Reset the redo stack.
      commit(RESET_REDO_STACK);
    }
  },
  undo: ({ dispatch, getters }) => {
    // The first entry in the historyStack is the base state of the canvas
    if (getters.historyStack.length > 1) {
      dispatch('applyCanvasState', 'undo');
    }
  },
  redo: ({ dispatch, getters }) => {
    if (getters.redoStack.length > 0) {
      dispatch('applyCanvasState', 'redo');
    }
  },
  applyCanvasState: ({ commit, getters }, actionType) => {
    //Lock the stacks for the incoming change
    commit(SET_HISTORY_LOCK, true)
    
    let canvasState;

    if (actionType === 'undo') {
      commit(PUSH_STATE_TO_REDO_STACK, getters.currentState);
      commit(POP_STATE_FROM_HISTORY_STACK);
      canvasState = getters.historyStack[getters.historyStack.length - 1]
    }
    
    if (actionType === 'redo') {
      canvasState = getters.redoStack[getters.redoStack.length - 1]
      commit(PUSH_STATE_TO_HISTORY_STACK, getters.redoStack[getters.redoStack.length - 1]);
      commit(POP_STATE_FROM_REDO_STACK);
    }

    //Make the new state the current state
    commit(SET_CANVAS_CURRENT_STATE, canvasState)

    //Update canvas with the new current state
    getters.canvas.loadFromJSON(getters.currentState, () => {
      //Unlock the stacks
      commit(SET_HISTORY_LOCK, false)
    });       
  },
  resetHistoryState: ({ commit }) => {
    commit(RESET_HISTORY)
  },
}
