import Vue from 'vue'
import Vuex from 'vuex'
import ui from './ui'
import canvas from './canvas'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui,
    canvas
  }
})
