import { SET_ACTIVE_TOOL, SET_ACTIVE_SWATCH, SET_SWATCHES, SET_ACTIVE_MODAL, SET_ACTIVE_ARTICLE, SET_ACTIVE_GROUP } from './mutations'

export default {
  setActiveTool: ({ commit }, toolId) => {
    commit(SET_ACTIVE_TOOL, toolId)
  },
  setActiveSwatch: ({ commit }, swatch) => {
    commit(SET_ACTIVE_SWATCH, swatch.id)
  },
  setSwatches: ({ commit }, swatches) => {
    commit(SET_SWATCHES, swatches)
  },
  setActiveModal: ({ commit }, modalId) => {
    commit(SET_ACTIVE_MODAL, modalId)
  },
  setActiveArticle: ({ commit }, articleId) => {
    commit(SET_ACTIVE_ARTICLE, articleId)
  },
  setActiveGroup: ({ commit }, groupId) => {
    commit(SET_ACTIVE_GROUP, groupId)
  }
}