import en from './en.json';
import cn from './cn.json';

const defaultLocale = 'en';

const languages = {
  en: en,
  cn: cn,
}

export { defaultLocale, languages };